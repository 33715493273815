
import {
	IonCard,
	IonCardContent,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonIcon,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { addIcons } from 'ionicons';
import { trash } from 'ionicons/icons';
import {
	GroupResult,
	Match,
	Player,
	Group,
	Result,
	SubResult,
} from '@/interfaces';

export default defineComponent({
	name: 'GroupDetail',
	data() {
		return {
			groupId: this.$route.params.id,
			resultItems: [],
		};
	},
	created() {
		addIcons({
			trash,
		});
	},
	computed: {
		group(): Group {
			return this.$store.getters.group(this.groupId);
		},
	},

	ionViewDidEnter() {
		this.doCalc();
	},
	methods: {
		calc(players: Player[], matches: Match[]): GroupResult[] {
			const group = [];
			for (const { name, id } of players) {
				const playerResult = {
					id,
					name,
					matchCount: 0,
					matchPoint: 0,
					gameWins: 0,
					gameLoses: 0,
					pointsWon: 0,
					pointsLost: 0,
				};
				const playerMatches = matches.filter(({ players }) =>
					players.includes(id)
				);
				playerResult.matchCount = playerMatches.length;
				let gameWins = 0,
					gameLoses = 0,
					pointsWon = 0,
					pointsLost = 0;
				playerMatches.forEach(({ games, players }) => {
					const result = {
						aGame: games.reduce(
							(sum, acc) => sum + (acc[0] > acc[1] ? 1 : 0),
							0
						),
						bGame: games.reduce(
							(sum, acc) => sum + (acc[0] < acc[1] ? 1 : 0),
							0
						),
						aPoints: games.reduce((sum, acc) => sum + acc[0], 0),
						bPoints: games.reduce((sum, acc) => sum + acc[1], 0),
					};

					// If current players is playerB, swap
					if (players[1] === id) {
						[result.aGame, result.bGame] = [
							result.bGame,
							result.aGame,
						];
						[result.aPoints, result.bPoints] = [
							result.bPoints,
							result.aPoints,
						];
					}

					gameWins += result.aGame;
					gameLoses += result.bGame;
					pointsWon += result.aPoints;
					pointsLost += result.bPoints;

					if (result.aGame > result.bGame) {
						playerResult.matchPoint += 2;
					} else {
						const isWo = games.every((game) => game.includes(0));
						if (!isWo) {
							playerResult.matchPoint += 1;
						}
					}
				});

				playerResult.gameWins = gameWins;
				playerResult.gameLoses = gameLoses;
				playerResult.pointsWon = pointsWon;
				playerResult.pointsLost = pointsLost;

				group.push(playerResult);
			}

			group.sort(
				(a, b) =>
					b.matchPoint - a.matchPoint ||
					b.gameWins / b.gameLoses - a.gameWins / a.gameLoses ||
					b.pointsWon / b.pointsLost - a.pointsWon / a.pointsLost
			);
			return group;
		},
		isDone(group: GroupResult[]): boolean {
			return (
				this.hasSameMatchPoint(group) ||
				this.hasDistinctMatchPoint(group)
			);
		},
		hasSameMatchPoint(group: GroupResult[]): boolean {
			return [...new Set(group.map((g) => g.matchPoint))].length === 1;
		},
		hasDistinctMatchPoint(group: GroupResult[]): boolean {
			return (
				[...new Set(group.map((g) => g.matchPoint))].length ===
				group.length
			);
		},
		hasSameGameQuota(group: GroupResult[]): boolean {
			return (
				[...new Set(group.map((g) => g.gameWins / g.gameLoses))]
					.length === 1
			);
		},
		hasDistinctGameQuota(group: GroupResult[]): boolean {
			return (
				[...new Set(group.map((g) => g.gameWins / g.gameLoses))]
					.length === group.length
			);
		},
		hasDistinctPointQuota(group: GroupResult[]): boolean {
			return (
				[...new Set(group.map((g) => g.pointsWon / g.pointsLost))]
					.length === group.length
			);
		},
		hasSamePointQuota(group: GroupResult[]): boolean {
			return (
				[...new Set(group.map((g) => g.pointsWon / g.pointsLost))]
					.length === 1
			);
		},
		groupExists(groups: any[], group: GroupResult[]): boolean {
			return (
				groups
					.map((g) => JSON.stringify(g))
					.filter((g) => g === JSON.stringify(group)).length >= 2
			);
		},
		run(
			players: Player[],
			matches: Match[]
		): { primGroup: GroupResult[]; groups: GroupResult[][] } {
			const groups = [];

			const primGroup: GroupResult[] = this.calc(players, matches);

			groups.push(primGroup.slice());

			if (!this.hasDistinctMatchPoint(primGroup)) {
				// Sort by group results
				const playerIndexes: number[] = [];

				let groupChunk = primGroup;
				while (playerIndexes.length < primGroup.length) {
					const currentPlayer = groupChunk.filter(
						(g) => !playerIndexes.includes(g.id)
					)[0];
					const playersWithSameMatchPoint = groupChunk
						.filter(
							(p) => p.matchPoint === currentPlayer.matchPoint
						)
						.map(({ id }) => id);

					if (playersWithSameMatchPoint.length > 1) {
						if (playersWithSameMatchPoint.length === groupChunk.length) {
							if (!this.groupExists(groups, groupChunk)) {
								groups.push(groupChunk);
							}
							const playersWithSameGamesQuota = groupChunk
								.filter(
									(p) =>
										p.gameWins / p.gameLoses ===
										currentPlayer.gameWins /
											currentPlayer.gameLoses
								)
								.map(({ id }) => id);
							if (playersWithSameGamesQuota.length > 1) {
								const subPlayers = players.filter(({ id }) =>
									playersWithSameGamesQuota.includes(id)
								);

								const subMatches = matches.filter(
									({ players }) =>
										players.every((id) =>
											playersWithSameGamesQuota.includes(
												id
											)
										)
								);
								const subGroup = this.calc(
									subPlayers,
									subMatches
								);
								if (!this.groupExists(groups, subGroup)) {
									groups.push(subGroup);
								}

								if (this.isDone(subGroup)) {
									for (const player of subGroup) {
										playerIndexes.push(player.id);
									}
									groupChunk = primGroup;
								} else {
									groupChunk = subGroup;
								}
							} else {
								playerIndexes.push(currentPlayer.id);
							}
						} else {
							const subPlayers = players.filter(({ id }) =>
								playersWithSameMatchPoint.includes(id)
							);

							const subMatches = matches.filter(({ players }) =>
								players.every((id) =>
									playersWithSameMatchPoint.includes(id)
								)
							);
							const subGroup = this.calc(subPlayers, subMatches);
							if (!this.groupExists(groups, subGroup)) {
								groups.push(subGroup);
							}

							if (this.isDone(subGroup)) {
								for (const player of subGroup) {
									playerIndexes.push(player.id);
								}
								groupChunk = primGroup;
							} else {
								groupChunk = subGroup;
							}
						}
					} else {
						playerIndexes.push(currentPlayer.id);
					}
				}

				primGroup.sort(
					(a: any, b: any) =>
						playerIndexes.indexOf(a.id) -
						playerIndexes.indexOf(b.id)
				);
			}
			return { primGroup, groups };
		},
		doCalc() {
			const { groups, primGroup } = this.run(
				this.group.players,
				this.group.matches
			);
			const res = [];
			for (const { name, matchPoint, id } of primGroup) {
				const a: Result = {
					id,
					name,
					MP: matchPoint,
					rounds: [],
				};
				res.push(a);
			}

			groups.shift();
			for (const group of groups) {
				for (const {
					id,
					matchPoint,
					gameWins,
					gameLoses,
					pointsWon,
					pointsLost,
				} of group) {
					const index = res.findIndex((r) => r.id === id);
					const b: SubResult = {
						MP: matchPoint,
						SK: `${gameWins}/${gameLoses}`,
						BK: `${pointsWon}/${pointsLost}`,
					};
					res[index].rounds.push(b);
				}
			}
			this.resultItems = res as any;
		},
		getName(id: number): string {
			const { name } = this.group.players.find(
				(p) => p.id === id
			) as Player;
			return name;
		},
		removeMatch(matchId: number) {
			const matchIndex = this.group.matches.findIndex(
				({ id }) => matchId === id
			);
			this.group.matches[matchIndex].games = [];
			this.$store.dispatch('updateGroup', this.group);
			this.doCalc();
		},
	},

	components: {
		IonCard,
		IonCardContent,
		IonButton,
		IonGrid,
		IonRow,
		IonCol,
		IonIcon,
	},
});
